import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2e9b1074&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=2e9b1074&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e9b1074",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PaymentType: require('/app/components/Payment/PaymentType.vue').default,PaymentAll: require('/app/components/PaymentMethod/PaymentAll.vue').default})
